import React from "react"
import KUTE from "kute.js"

function VideoSphaerenSvg(props) {
  //declaring refs for svg paths and tweens
  const treeV1Ref = React.useRef()
  const treeV2Ref = React.useRef()
  const morphTween = React.useRef()
  const reverseMorphTween = React.useRef()
  //declaring the animation
  function morph() {
    const tweenProps = {
      duration: 5000,
      morphPrecision: 10,
    }
    morphTween.current = KUTE.fromTo(
      treeV2Ref.current,
      { path: treeV2Ref.current },
      { path: treeV1Ref.current },
      {
        onComplete: () => {
          reverseMorphTween.current.start()
        },
        ...tweenProps,
      }
    )
    reverseMorphTween.current = KUTE.fromTo(
      treeV2Ref.current,
      { path: treeV1Ref.current },
      { path: treeV2Ref.current },
      {
        onComplete: () => {
          setTimeout(() => {
            morphTween.current.start()
          }, 2000)
        },
        ...tweenProps,
        delay: 2000,
      }
    )
    morphTween.current.start()
  }
  // function pauseMorph() {
  //   if (morphTween && morphTween.current) morphTween.current.pause()
  // }

  function handlePointerEnter() {
    if (!morphTween.current) {
      morph()
    } else {
      if (morphTween.current.paused) {
        morphTween.current.resume()
      }
    }
  }
  // function handlePointerLeave() {
  //   pauseMorph()
  // }
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 320 294"
      onPointerEnter={handlePointerEnter}
      // onPointerLeave={handlePointerLeave}
      role="img"
    >
      <defs />
      <title id="uniqueTitleID">{props.title || "video sphere"}</title>
      <ellipse cx="160" cy="145" fill="#fff" opacity=".8" rx="150" ry="140" />
      <g className="transform scale-90 translate-x-4 translate-y-4">
        <path
          fill="#000"
          className="invisible opacity-0"
          ref={treeV1Ref}
          d="M81 117l-1 6v2c1 3 7 9 12 10l4-1h6l1 5-2 9-1 4 2 4-1 8v7c0 4 3 7 6 10l1 1c2 2 4 5 8 5 3-1 3-4 3-7 5-2 8-6 8-14 0-2 1-2 3-2h4l3-2 6-2 5 2c2 3 1 6 0 9v13c0 10-3 20-8 23-10 0-19-3-25-6l-1-1-3-2c-8-4-15-8-21-14 8 12 22 22 38 29l7 2 15 5a86 86 0 0035-5c15-3 25-12 33-23l-21 14h-2l-6 3c-8 1-17-2-20-7-3-4-5-17-4-23l6-11 6-2c5-3 10-4 16-3l17 3h4c4 1 8 1 11 4l-1 3c1 2 1 4-2 4-2 5 1 9 5 8l3-6 3-4 3-11 4-9v-3c2-2 3-5 1-6-2 0-2 1-3 2v2c-2 0-3 1-4 3l-4 2-1 2c-1 3-1 6-3 7-3 1-10 0-15-1h-1l-4-1c-4-1-8-1-9-3 0-4 3-7 5-9l4-3h8l1-2 2-1c3 5-4 7-8 8 0 2 4 1 5 1h2l5-6c2-3 4-6 4-11l-3-2c0-2-1-2-2-3v-2l6-1 3-4-1-1-1-2-6 5h-2l-1 2v1l1 3-1 6h-1c-2 2-5 5-7 4-2 0-2-2-2-3v-1c-3 0-4 2-5 4l-2 4c-2 0-3-1-3-2h-3c0 2 2 5 4 6l-6 6-12 4c-6 2-16 5-19-1-2-3 0-8 2-10 1-2 4-3 6-4l5-1 4-6 4-5 1-1c2-1 5-1 5-3l-1-4 1-4c-2-3-1-11 3-12l4 4 2 4-1 4 1 6-1 3c-1 4-2 9 3 11l1-6-1-2c0-2-1-4 2-4l1 2c0 1 1 2 3 2 0-3 2-5 5-7l-2-6a5 5 0 014-1l2-3c1-3 3-5 5-5l4-1 2-1c2-1 3-2 6 0a7 7 0 004-4l-5-11-2-4-4-6-9-9-7-6c-12-9-29-14-47-15-7-1-13 0-18 3l-6 6-10-2c-4-1-7 0-9 2-3 1-4 3-5 5l-3 5-9 5-6-1c-2 2-10 10-6 16 2 3 14 7 13-2l3-3 4-2 4-1 2 1 2 3 2-1h5l6 1 6 1c0 3-1 8-3 11l1 4h2c1 0 6 5 7 8v3c0 2 0 4 2 5 3 0 5-3 7-5a26 26 0 014-4c2-1 3-2 2-4l-3-1-4-4-4-2 1-2c2 0 5 1 7 3l3 1 2 1 1 2h2l5 3 1 4 1-1h2v6c3 4-3 8-8 12l-1 2-2 1c-5 3-9 7-9 13v3l1 7h-7c-2 0-5-1-7 1l-4 2c-3 2-6 3-9 2l2-7c2-2 4-5 4-9 1-3 4-5 6-6l3-1 7-11v-2l1-3-4 1-3-2c0-3-3-5-5-7-3-1-5-3-5-5a4 4 0 011-3l-1-4c-3-2-6-4-9-4l-13 2-4-2-11 2-4 6c-7 0-9 6-11 11zm87 15l8-7v1l4 5-4 8c-3 0-5 1-7 3-3 2-6 4-10 4-2-6 2-9 5-12l4-2z"
        />
        <path
          ref={treeV2Ref}
          fill="#000"
          d="M182 93l-9-3v-5-4l5-6 3-2c9-1 24 7 28 13 2 2 4 1 5 5-4 4-2 12-7 15-3 2-11 3-16 4l-11 7c-2 2-5 2-7 3l-4 5-2 2v16c2 2 0 9 0 14 4-4 9-4 11-12l-4-5-2 1-1-2 1-10c5-3 10-9 16-11l24-4 8-9 7-1 2 5c1 2 7 8 6 11a4 4 0 01-4 3h-4l-6 2-3 1c-3 1-3 4-5 7-2 2-5 0-5 4l1 4c-3 2-4 4-5 7h-1c-2-1-1-3-4-3v11h-1l-3-2c-3-4 3-17 0-23 0-3-3-5-5-7v1c-6 2-3 16-3 20l-8 4c-2 3-2 8-5 10-4 3-8 1-8 6l-1 12h1c2 1 7-1 9-2 9-3 17-3 22-10l-3-3-1-4c3 0 3 2 6 3l4-8 3-1 1 1 1 4h2l5-4c2-3 0-9 2-11l3-1c2-2 2-5 6-6l1 5c-2 2-2 3-7 3l-2 1v1c2 5 6 3 6 9-5 7-4 15-16 15l-1-3c4 0 9-2 9-6l-1-1-2 3h-8c-2 3-11 9-9 12 3 4 22 6 28 5 3-4 2-5 4-10l9-5c1-1 0-3 3-3l1 3c-5 7-6 18-10 26-1 4-2 8-5 10-5 3-7-4-6-8 1 0 2 0 3-2v-5c-5-4-8-2-15-4-12-2-22-4-33 0l-6 2c-2 1-3 5-4 7-2 4-3 9-2 13 1 5 1 10 4 14 1 2 4 3 6 4 13 7 27-2 35-8 3-2 5-6 9-7v1c-5 4-8 11-14 14-14 11-47 20-68 9-4-2-14-3-16-7l-1-2 4-1c6 6 20 5 26 0 8-6 9-25 4-33-7-11-28-9-42-6-4 1-8 0-12 2l-3 2v1c-2 1-9-2-10-3-2-7-7-12-8-21l3-3a4 4 0 014 1l8 10c-2 2-1 5 0 7 1 1 1 2 3 2h7c6-1 18-1 21-6-4-5-6-11-15-10l-3-1c0-2-1-2-3-3-2 3-5 0-7-1-7-3-11-12-8-21 1-1 5-2 6-1 3 1 3 3 5 5l4 1 1 12c2 1 3 3 7 3h1l1-4 2-1 1 1c1 2 2 7 5 8a6 6 0 015-3l-1 4-3 3h1c5 7 19 11 30 12l1-1-1-14c-3-1-8-2-10-5-1-2-1-6-4-8-2-2-7-1-7-5-1-2 2-3 1-5 0-1-2-2-1-4l1-5c-2-8-14-16-23-14l-1 4 3 1c2-4 12 3 15 6-4 2-4 8-4 14-6 1-7-3-12-4v-3h-3c-3-1-4-7-7-8l-3-1-6-2h-5l-3-3c1-4 6-13 10-14l1 2c2 2 0 4 0 7l3 2c8-8 13-6 26-6 2 2 6 3 8 6l2 8 2 2c2 3 1 7 6 7l1-3c-5-6-5-18-8-25v-3c-2-3-7 0-9-2-3-1-7-4-12-3-5 2-5 6-11 7l-3-3c-1-3-2-4-1-7 4-3 13-13 18-14l2 3 1 5c1 2 7 7 10 7l7 1 3 4 2 10 4 14c1 4 0 8 2 11l5 2v-11c-2-3 0-14 0-18l-3-3c-3-4-7-6-7-13a25 25 0 0013-15c2-6-3-10 7-10l8 1v3l-3 6-9 4-2 3c-2 4-6 5-7 10 3 3 3 10 7 11l2-3-4-9 5-5 4 2c1 2 0 5 2 6l8 1 1 5-2 3-3 2a10 10 0 00-4 7l4 1 5-6c3-3 8-2 12-4 5-2 9-9 7-16-4-1-4-2-9-2l-1 2-1 3z"
        />
        <g fill="#231F20">
          <path d="M288 49c-16 13-33 24-49 36l-3-4 46-40 6 8zM79 86l-2-1-48-32c1-2 3-3 3-5l1 1 41 32 5 5M295 59c-17 9-33 21-50 30 0 1-1 2-2 1 0-2 3-3 5-5a5777 5777 0 0044-31 17 17 0 013 5M276 33l-41 40-3 2c0-2 2-4 4-6a5351 5351 0 0036-40 17 17 0 014 4M70 101l-7-2c-15-7-32-16-48-22l3-5 47 25 5 4M308 83l-43 17c-5 2-9 5-14 6 0-3 4-3 6-4l49-23a35 35 0 012 4M68 106l-2 5L9 92l4-10 55 24zM87 74l-4 4-44-42 8-7 40 45M319 126l-61 7a30 30 0 01-1-5l60-12 2 10M61 134h-7l-53-4v-5h1l52 7 7 2zM320 136v1a285 285 0 01-15 1l-40 1h-7l15-3 39-3 8-1v4M0 154v-1l15-1 39-1c2 0 5-1 7 1-20 3-41 4-61 6v-4M319 160a45 45 0 010 5l-61-8v-1l1-1a4762 4762 0 0160 5M61 158l1 4c-19 5-40 8-59 12l-2-10 60-6M311 198l-4 10-56-24a29 29 0 012-5l58 19M68 185h1c0 2-2 2-3 2-16 8-35 16-51 25a13 13 0 01-3-5l56-22M305 213l-3 5-54-29h1v-1l56 25M295 228l-3 4-50-36h1v-1l52 33M76 200h1c0 2-3 4-5 5l-44 31a18 18 0 01-3-5l51-31" />
        </g>
        <g fill="#231F20">
          <path d="M71 222l4-1c9 2 14 14 5 19-2 0-3-3-6-4 1-2 3-2 3-5-3-4-6 1-6 3-1 3 1 8 1 12 0 5-5 12-9 13-7 1-10-5-13-9v-2c0-4 3-9 5-9s3 4 5 5l-2 6c2 2 5-1 6-3v-18l7-7M271 243v1a79 79 0 00-7 7c-10-5-19-11-27-17 5 7 14 15 21 22-1 1-3 5-5 5l-7-6-20-21c1-4 5-5 7-8l27 16c-6-7-15-14-21-22l4-4c10 8 18 18 28 27M91 235c5 3 13 4 15 10 1 4-6 18-10 19s-6-2-10-3a78 78 0 00-6 12l-7-4c5-12 12-23 18-34zm-2 19c5 5 12-8 6-10l-6 10zM225 242c-3 0-4 3-7 3a36 36 0 004 7l7-4a16 16 0 014 7l-7 3c0 4 3 5 4 9a40 40 0 008-5c1 2 2 5 4 6a85 85 0 01-15 9l-20-33c4-4 10-6 14-9 2 1 3 4 4 7M113 246l7 2a129 129 0 01-4 15 10 10 0 005 1 123 123 0 004-15l8 3-11 37-8-2 4-15a9 9 0 00-4-2l-5 16-7-2 11-38M205 265c9 0 6 13 11 18l-7 2c-5-3-2-17-11-14a107 107 0 006 16 23 23 0 01-8 2l-12-37c6-1 11-5 16-4s10 13 5 17zm-9 0l3-2a13 13 0 00-2-7c-7-1-1 5-1 9M154 254l2 40-8-1v-6l-6-1-1 6a62 62 0 01-9-1l11-38 11 1zm-11 25h4v-17l-4 17zM171 294h-4l-5-39a125 125 0 0117-2l1 7a51 51 0 01-9 1l1 8 8-1 1 8a49 49 0 00-8 1l1 9a42 42 0 008-1l1 7-12 2" />
          <g>
            <path d="M142 1l8-1c16-3 13 13 13 25 0 5 1 11-1 13-4 5-13 3-20 3V1zm8 7l1 26c6 1 4-8 4-13l-1-13h-4M129 2l5 40a22 22 0 01-9 1l-5-40c3 0 6-2 9-1zM177 2l16 2-1 8-8-1c-2 1-1 5-2 7 2 2 6 1 9 2l-1 7-9-1c-1 2 0 7-2 9 3 2 7 1 9 2l-1 7-16-2a1911 1911 0 016-40zM106 38l-4-32 8-2 3 41-10 3-17-37a53 53 0 018-3l12 30M225 15v5c-5 10-4 26-14 31h-1c-5-2-12-2-13-7-2-5 2-12 4-19 2-6 4-15 8-16 6-2 11 1 16 6zm-12 1l-5 15c-1 2-5 11-1 12 4 0 5-9 6-12 2-6 4-10 3-14a8 8 0 00-3-1" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default VideoSphaerenSvg
