import React from "react"

function SquareSvg(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 304 314"
      role="img"
    >
      <defs />
      <title id="uniqueTitleID">{props.title || "square"}</title>
      <ellipse cx="158" cy="158" fill="#fff" opacity=".8" rx="140" ry="145" />
      <g className="transform scale-90 translate-x-5 translate-y-4">
        <g fill="#231F20">
          <path d="M145 0h3l1 54v7l-5-54c0-2-2-7 1-7M137 1l1 3c2 19 3 39 6 58h-5L128 2l9-1M174 1c-3 20-5 42-10 61l2-30 3-30c1-2 4-1 5-1M214 12c-8 20-17 38-26 57a34 34 0 01-4-1l21-59 9 3M97 11l17 43 5 13c-2 0-3-3-4-6L93 13l4-2M224 17l-31 55c1-5 4-9 6-14l20-43 5 2M105 74c-2 0-3-3-5-5L70 26l4-3 31 51zM246 31l-40 50c0-3 2-5 4-7l32-46a14 14 0 014 3M100 77c0 2-2 2-3 4L63 41l-5-6 7-6 35 48M275 61l-52 37c-2 0-2-2-3-3l49-42 6 8M82 96a104 104 0 01-13-8L32 62l3-4h1l41 33c2 2 4 2 5 5M62 159l-15 1H2v-5h1l53 3 6 1M281 70l-55 33 5-5 47-32a17 17 0 013 4M74 109l-7-2-48-23 2-4h1l49 27 3 2M293 93l-46 18-15 5 59-28a31 31 0 012 5M71 114l-1 4-57-20 4-9 54 25M301 123l2 10c-22 2-43 5-65 6l-1-4 64-12M65 139l-30-2-30-3v-5h1l52 8c3 1 5 0 7 2M163 314h-4l-1-54c0-3-1-6 1-7l5 54c0 2 1 7-1 7M171 313c-2 0-1-2-1-3-3-19-4-39-6-58h5l10 60-8 1M133 313l10-61-2 30-3 31h-5M93 302l26-57a34 34 0 014 2l-21 59-9-4M210 302l-16-42c-2-5-5-9-5-14l3 6 22 48-3 2M83 298l31-55c-1 5-4 9-6 14l-20 43-5-2M202 239c2 0 3 3 5 5l30 43c0 2-3 2-3 3l-32-51zM62 284l39-49c0 2-2 4-4 6l-32 46a14 14 0 01-4-3M207 236c0-2 2-2 3-4l35 40 5 6-8 6-35-48M32 255l52-38 3 3-49 42-6-7M225 217a104 104 0 0112 8l37 26-3 4v-1l-41-33c-2-1-5-2-5-4M26 246l55-33c-1 2-4 3-6 4l-46 33a17 17 0 01-3-4M243 147c9-2 19-2 29-3l31-2v5l-53 1-7-1" />
        </g>
        <path
          fill="#000"
          d="M23 200H11h-1v-6-1h12l1-1-1-1v-1h-6l-4-2c-2-1-2-3-2-5v-4l2-5 6-1h11l1 1v5l-1 1H19v2h1l5 1 5 2 1 4v4l-2 5-6 2zm53 3h-6-1v-3c-3 0-5-1-6-3l-1-5v-12-3l2-3 4-1 5-1 6 1 4 1 2 3v18a5 5 0 01-1 2l-3 2-3 1v2l-1 1h-1zm-6-11l1 1h5l1-1v-11l-1-1h-5l-1 1v11zm70 1l-1 3-1 2-4 2-6 1-6-1-3-2-2-2-1-3v-19l1-1h8v18l1 2h5v-19l1-1h8v20zm41-12l-1 5h6l-2-5v-1a1 1 0 00-1 0h-1l-1 1zm-2 18v1h-7-1v-11-3l1-3 4-9v-1h13l1 1 3 9a26 26 0 012 6v10l-1 1h-7-1v-7h-7v6zm55-20v5h5v-3-1l-2-1h-3zm-1 21h-6-1v-26-1h14l6 1 1 5v4l-2 2 4 2 1 4v9h-8l-1-1v-6-1l-1-1h-6v9h-1zm67 0h-18-1v-26-1h20v7h-12v3h9v6l-1 1h-8v3h12v7h-1z"
        />
      </g>
    </svg>
  )
}

export default SquareSvg
