import React from "react"

const Video = ({ url, poster, videoRef, ...props }) => {
  return (
    <video
      key={url}
      ref={videoRef}
      playsInline
      width="100%"
      height="100%"
      onContextMenu={e => e.preventDefault()}
      poster={poster}
      {...props}
    >
      <source src={url} />
      <track kind="captions" srcLang="en" label="english_captions" />
    </video>
  )
}
export default Video
