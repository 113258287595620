import React from "react"

function CircleSvg(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 306 313"
      role="img"
    >
      <defs />
      <title id="uniqueTitleID">{props.title || "circle"}</title>
      <ellipse cx="160" cy="150" fill="#fff" opacity=".8" rx="147" ry="135" />
      <g className="transform scale-90 translate-x-5 translate-y-4">
        <g fill="#231F20">
          <path d="M145 0h3l1 54v7l-5-54c0-2-2-7 1-7M137 1l1 3c2 19 3 39 6 58h-5L128 2l9-1M174 2c-5 40-7 83-13 123l3-61 3-61c1-4 5-1 7-1M214 12c-8 20-17 38-26 57a34 34 0 01-4-1l21-59 9 3M103 11h-2c14 31 30 61 44 93 4 10 10 19 12 30-4-2-6-8-9-13L93 16c2-3 4-3 7-5M224 17l-31 55c1-5 4-9 6-14l20-43 5 2zM105 74c-2 0-3-3-5-5L70 26l4-3 31 51zM246 34c-28 33-56 68-85 100 1-5 5-9 8-14l69-92a31 31 0 018 6M100 77c0 2-2 2-3 4L63 41l-5-6 7-6 35 48M275 60c-17 14-35 26-52 38-2 0-2-2-3-3l49-42 6 8M155 145a260 260 0 01-31-19L32 67l7-9 2 1 102 75c4 3 11 5 12 11M157 156a269 269 0 01-39 2H2v-7l2-2 135 4c6 1 13-1 18 3M281 70c-19 10-37 23-56 33 2-3 4-4 6-5l47-33a17 17 0 013 5M74 109c-3 0-5-2-7-3L19 84l2-4h1l49 27 3 2M293 97l-99 37c-11 4-22 9-33 11 41-20 85-38 128-57a63 63 0 014 9zM71 114l-1 4-57-20 4-9 54 25M301 123l2 10-66 6v-4l64-12M65 139l-30-2-30-3v-5h1l52 8c3 0 5 0 7 2M243 160c10-2 20-1 30-2l31-1v5h-1l-53-1-7-1M161 156c22-4 46-5 70-8l72-8a29 29 0 01-1 7l-125 9h-16" />
        </g>
        <path
          fill="#000"
          d="M102 207h-9l-7-2c-2-1-2-3-2-6v-12c0-2 0-4 2-5 1-2 4-2 7-2h10l1 1v5l-1 1H93l-1 2v9l1 1 2 1h8l1 1v5l-1 1h-1zm7 0h-1l-1-1v-5l1-1h4v-13h-4l-1-1v-5l1-1h18v7h-5v13h5v7h-17zm31-21v5h4v-4l-2-1h-2zm-2 21h-7v-27h14l6 1 1 5v4l-2 2 4 2 1 4v9h-8l-1-1v-7l-1-1h-5v8l-1 1h-1zm39 0h-9l-7-2c-2-1-2-3-2-6v-12c0-2 0-4 2-5 1-2 4-2 7-2h10l1 1v5l-1 1h-10l-1 2v9l1 1 2 1h8l1 1v5l-1 1h-1zm27 0h-21v-27h8v20h6v-7l1-1h6l1 1v14h-1zm24 0h-18l-1-1v-25l1-1h19l1 1v4l-1 2h-11v3h8l1 1v4l-1 1-1 1h-7v3h11l1 1v5l-1 1h-1z"
        ></path>
      </g>
    </svg>
  )
}

export default CircleSvg
