import SquareSvg from "../components/reusables/SquareSvg"
import VideoSphaerenSvg from "../components/reusables/VideoSphaerenSvg"
import CircleSvg from "../components/reusables/CircleSvg"

export default function getSvgLogoComponent(logo) {
  switch (logo.title) {
    case "Video Sphere Logo":
      return VideoSphaerenSvg
    case "Video Sphaeren Logo":
      return VideoSphaerenSvg
    case "Square Logo":
      return SquareSvg
    case "Circle Logo":
      return CircleSvg
    case "Kreis Logo":
      return CircleSvg
    default:
      break
  }
}
